.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.app-toast-top {
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  z-index: 65535;
  padding: 12px 16px;
  background-color: rgba(255, 255, 255, .8);
  color: black;
  font-weight: 700;
  border-radius: 6px;
  box-shadow: 0 2px 14px 2px rgba(0, 0, 0, .25);
  text-align: center;
}

body.app--shown-modals {
  overflow: hidden;
}